import React, { useState, useEffect } from 'react';
import MainRow from '../../components/MainRow';
import ArtistRow from '../../components/ArtistRow';
import MusicVideoRow from '../../components/MusicVideoRow';
import ContectRow from '../../components/ContectRow';
import { combinedImages } from '../../data/images'; // 데이터 파일 임포트

export default function Mainpage() {
  const [shuffledImages, setShuffledImages] = useState([]);

  // 이미지 배열을 섞는 함수
  const shuffleArray = (arr) => {
      return arr
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
  };

  useEffect(() => {
      setShuffledImages(shuffleArray(combinedImages));
  }, []);
    
    return (
      <div>      
        <MainRow images={shuffledImages.slice(0, 1)}  id="Main"/>
        <ArtistRow images={shuffledImages.slice(1, 7)} id="Artist"/>
        <MusicVideoRow images={shuffledImages.slice(7, 14)} id="Music"/>
        <ContectRow images={shuffledImages.slice(14, 19)} id="Contect"/>
      </div>
    )
  }