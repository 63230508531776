import React from "react";
import "./MainRow.css"; // CSS 파일 임포트

export default function MusicVideoRow({images}){
  return (
    <div className="container">
        <section className="section">
            {images.map((image, index) => (
                <a key={index} href={image.link} target="_blank" rel="noopener noreferrer">
                    <img src={image.src} alt={`MusicVideoRow ${index + 1}`} />
                </a>
            ))}
        </section>
    </div>
  );
}
