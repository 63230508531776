import { Outlet, Route, Routes } from "react-router-dom";
import './App.css';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Mainpage from "./pages/MainPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
const Layout = () =>{
  return (  
    <div className="App">
      <Nav />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  return (
    <div className="App">
        <Routes> 
          <Route path="/" element={<Layout />}>
            <Route index element={<Mainpage />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
