
export const images = Array.from({ length: 20 }, (_, i) => ({
    src: `/images/${(i + 1).toString().padStart(4, '0')}.png`
}));

export const imageLinks = [
    // 여기에 링크들을 순서대로 저장
    "",
    "https://www.youtube.com/channel/UC5zhL_3ZYWpuxd50cFb3BAw", // 2번 이미지
    "https://youtu.be/TmzsfOAyOQQ", // 3번 이미지
    "https://youtu.be/KIhT30-OigI",// 4
    "https://youtu.be/EFN8nFrfchs",
    "https://youtu.be/DyVZf69GYak",
    "https://youtu.be/8-Uc_jEOEgw",
    "https://www.instagram.com/bread_music_official/",
    "https://www.tiktok.com/@officialbreadmusic",
    "https://open.spotify.com/playlist/7CWjcTgNpT1MO99Ra2RiNF?si=adc53be9bb514eb0",
    "https://open.spotify.com/playlist/37i9dQZF1DX889U0CL85jj?si=0b8f1ca378f94574",
    "https://open.spotify.com/album/2eiejkTwbScMVOdmE6YsHL?si=710ON7RWTeSHd8iTNJpuZQ",
    "https://open.spotify.com/album/36GcEXn3Pei0NPmhDHa85m?si=M1XXKw5oS4yEvgh-C0Fk7g",
    "https://open.spotify.com/album/0w5FMAo9rylT8tngAoGu20?si=NiHJVlemTZKHUJtHxsSAXA",
    "https://open.spotify.com/album/6hXpsaMDZvXHfnobY8Xt4Y?si=gYDLH8HJQcOeZGrFYra-pQ",
    "https://open.spotify.com/album/6JLR5w1WhA0Czco3DF2uun?si=e2hBDI19RAW51jsD6sT_rQ",
    "https://open.spotify.com/album/6JLR5w1WhA0Czco3DF2uun?si=qdy-Kf7uQmeiZCmxeWGWGw",
    "https://open.spotify.com/album/5XpxlMqHwltd959OEVAiVu?si=Kw-p6cy9TDWZx1LjV8n5fg",
    "https://en.wikipedia.org/wiki/Jean_Valjean",
    "https://en.wikipedia.org/wiki/Croissant" // 20번 이미지
];

export const combinedImages = images.map((img, index) => ({
    ...img,
    link: imageLinks[index] || "#",
    refreshOnFirst: index === 0  // 첫 번째 이미지에 대해 true 설정
}));
