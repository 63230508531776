import React from "react";
import "./MainRow.css"; // CSS 파일 임포트

export default function MainRow({ images }) {
    const handleClick = (image) => {
            window.location.reload();
        
    };

    return (
        <div className="container">
            <section className="section">
                {images.map((image, index) => (
                    <div key={index} onClick={() => handleClick(image)}>
                        <img src={image.src} alt={`MainRow ${index + 1}`} />
                    </div>
                ))}
            </section>
        </div>
    );
}