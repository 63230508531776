import React, { useEffect }  from 'react';
import "./Nav.css"; 

export default function Nav() {
  
  useEffect(() => {
  },[] );
 


    return (
      <nav className={`nav`}>      
          {/* <NavImg
            alt="logo"
            src={process.env.PUBLIC_URL + "/logo/logo.png"}
            
          /> */}
         
      </nav>
    )
  }